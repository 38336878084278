import { Text } from "@doar/components";
import { useAppSelector } from "src/redux/hooks";
import { getUserInfo } from "src/redux/slices/global";

const EXTENSION_URL = 'https://chromewebstore.google.com/detail/kjmdiplgjdmhnlpilhnnkajdbhifbgea';

const PMSES = new Set(["uplisting"]); // PMSes that require the extension for besty lite features to work

const isExtensionRequired = (pms: string) => PMSES.has(pms);

const Warning = () => {
    const userInfo = useAppSelector(getUserInfo);

    if (isExtensionRequired(userInfo.pms) && userInfo.extensionInstalledAt === null && !userInfo.webAppCredsIsExpired) {
        return (
            <div style={{ backgroundColor: "red", padding: "20px", color: "white", display: "flex", justifyContent: "center" }}>
                <div style={{ display: "inline-block", textAlign: "left" }}>
                    <div style={{ marginBottom: "10px" }}>
                        <strong>To complete your Besty integration with Uplisting, follow these steps:</strong>
                    </div>
                    <ol style={{ margin: "0", paddingLeft: "20px" }}>
                        <li>
                            Install our latest Chrome extension <a href={EXTENSION_URL} style={{ color: "white", textDecoration: "underline" }}>here</a>.
                        </li>
                        <li>
                        With the extension installed and running, go to your <a href="https://app.uplisting.io" style={{ color: "white", textDecoration: "underline" }}> Uplisting dashboard</a>.
                        </li>
                        <li>
                            Make sure that you are logged into your Uplisting account. Then, refresh the page.
                        </li>
                        <li>
                            Return to this page and hit refresh. This red banner should disappear.
                        </li>
                    </ol>
                    <div style={{ marginTop: "10px" }}>
                        If the banner persists, please contact us for support.
                    </div>
                </div>
            </div>
        );
    }

    if (isExtensionRequired(userInfo.pms) && (userInfo.extensionInstalledAt === null || userInfo.webAppCredsIsExpired)) {
        return (
            <div style={{ backgroundColor: "red", padding: "20px", color: "white", display: "flex", justifyContent: "center" }}>
                <div style={{ display: "inline-block", textAlign: "left" }}>
                    <div style={{ marginBottom: "10px" }}>
                        <strong>Your Besty integration with Uplisting needs to be refreshed. Follow these steps to restart Besty: </strong>
                    </div>
                    <ol style={{ margin: "0", paddingLeft: "20px" }}>
                        <li>
                            Make sure that you have our latest Chrome extension <a href={EXTENSION_URL} style={{ color: "white", textDecoration: "underline" }}>installed</a> and running.
                        </li>
                        <li>
                            With the extension installed and running, go to your <a href="https://app.uplisting.io" style={{ color: "white", textDecoration: "underline" }}> Uplisting dashboard</a>.
                        </li>
                        <li>
                            Make sure that you are logged into your Uplisting account. Then, refresh the page.
                        </li>
                        <li>
                            Return to this page and hit refresh. This red banner should disappear.
                        </li>
                    </ol>
                    <div style={{ marginTop: "10px" }}>
                        If the banner persists, please contact us for support.
                    </div>
                    
                </div>
            </div>
        );
    }

    return null;
};

export default Warning;
