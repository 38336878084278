import { StyledText } from "./style";
const MessageText = ({ text }) => {
    return (
        <StyledText mt="5px" mb="0px">
            {
                (text || "").split("\n").map((value, index) => {
                    return (
                    <span key={index}>
                        {value}
                        <br />
                    </span>
                    )
                })
            }
        </StyledText>
    );
};

export default MessageText;
