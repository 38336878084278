import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, Label, Input } from "@doar/components";
import styled from "styled-components";
import { useAppSelector } from "../redux/hooks";
import { isDataFormFilledOut, submitDataCollection } from "../api";
import { getUserInfo } from "../redux/slices/global";

//
// // Global style to handle body overflow when modal is open
// const GlobalStyle = styled(div)`
//     po
// `;

export const StyledModalBody = styled(({ ...rest }) => <ModalBody {...rest} />)`
  font-size: 16px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
  gap: 15px;
  //overflow-y: scroll;
`;

// Styled modal that covers the entire screen and appears in front of everything
const StyledModal = styled(Modal)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1050;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

// Styled InputGroup for form inputs
const StyledInputGroup = styled(InputGroup)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
`;

// Styled label for input fields
const StyledLabel = styled(Label)`
  width: 40%;
  margin-right: 10px;
  text-align: right;
  font-size: 0.9rem;
  font-weight: bold;
`;

// Styled input field
const StyledInput = styled(Input)`
  width: 55%;
`;

// Styled button for modal footer
const StyledButton = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s;

  &:hover {
    background-color: #45a049;
  }

  &:disabled {
    background-color: #c8c8c8;
    cursor: not-allowed;
  }
`;

// Main DataCollection component
const DataCollection = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    accountSid: "",
    authToken: "",
    businessName: "",
    socialMediaProfileUrls: "",
    websiteUrl: "",
    businessRegionsOfOperation: "USA_AND_CANADA",
    businessType: "Partnership",
    businessRegistrationIdentifier: "EIN",
    businessIdentity: "direct_customer",
    businessIndustry: "HOSPITALITY",
    businessRegistrationNumber: "",
    email: "",
    friendlyName: "FORMATED LATER",
    status: "active",
    jobPosition: "CEO",
    lastName: "",
    phoneNumber: "",
    firstName: "",
    businessTitle: "CEO",
    city: "",
    customerName: "",
    isoCountry: "US",
    postalCode: "",
    region: "",
    street: "",
    streetSecondary: "",
    evaluationResult: "",
  });

  const [dataFilledOut, setDataFilledOut] = useState(false);

  const { userToken } = useAppSelector(getUserInfo);
  console.log("DataCollection userToken:", userToken);

  const handleSubmit = async () => {
    await submitDataCollection({ user_token: userToken, data_form: formData });

    window.location.reload();
  };

  useEffect(() => {
    const getDataFilledOut = async () => {
      const response = await isDataFormFilledOut({ user_token: userToken });
      console.log("Response:", response);
      setDataFilledOut(response.data.result);
    };
    getDataFilledOut();
  }, [userToken]);

  const handleChange = (field) => (e) => {
    setFormData({
      ...formData,
      [field]: e.target.value,
    });
  };

  if (dataFilledOut)
    return (
      <Modal show={isOpen} onClose={onClose} size="lg">
        <ModalBody>
          <h1>
            <h1>Data Collection</h1>
          </h1>
          <div>
            <p>Thank you for submitting your data!</p>
            <p>We will review your data and get back to you soon.</p>
          </div>
        </ModalBody>
      </Modal>
    );

  return (
    <>
      {/*<GlobalStyle modalOpen={isOpen} />*/}
      <Modal show={isOpen} onClose={onClose} size="lg">
        <div style={{ padding: "2%", textAlign: "center", width: "100%", fontWeight: "bold" }} onClose={onClose}>
          <h5>Phone Number Data Registration Form</h5>
        </div>
        <ModalBody>
          <div style={{ overflowY: "scroll", maxHeight: "500px" }}>
            {/* Dynamically render InputGroups for each form field */}
            <StyledInputGroup>
              <StyledLabel>Official Business Name</StyledLabel>
              <StyledInput
                value={formData.businessName}
                onChange={handleChange("businessName")}
                placeholder="Enter Business Name"
              />
            </StyledInputGroup>
            <StyledInputGroup>
              <StyledLabel>Social Media Profile URL</StyledLabel>
              <StyledInput
                value={formData.socialMediaProfileUrls}
                onChange={handleChange("socialMediaProfileUrls")}
                placeholder="Enter Social Media Profile URLs"
              />
            </StyledInputGroup>
            <StyledInputGroup>
              <StyledLabel>Company Website URL</StyledLabel>
              <StyledInput
                value={formData.websiteUrl}
                onChange={handleChange("websiteUrl")}
                placeholder="Enter Website URL"
              />
            </StyledInputGroup>

            {/*<StyledInputGroup>*/}
            {/*  <StyledLabel>Business Regions Of Operation</StyledLabel>*/}
            {/*  <StyledInput*/}
            {/*    value={formData.businessRegionsOfOperation}*/}
            {/*    onChange={handleChange("businessRegionsOfOperation")}*/}
            {/*    placeholder="Enter Business Regions Of Operation"*/}
            {/*  />*/}
            {/*</StyledInputGroup>*/}
            {/*<StyledInputGroup>*/}
            {/*  <StyledLabel>Business Type</StyledLabel>*/}
            {/*  <StyledInput*/}
            {/*    value={formData.businessType}*/}
            {/*    onChange={handleChange("businessType")}*/}
            {/*    placeholder="Enter Business Type"*/}
            {/*  />*/}
            {/*</StyledInputGroup>*/}
            {/*<StyledInputGroup>*/}
            {/*  <StyledLabel>Business Registration Identifier (EIN Number)</StyledLabel>*/}
            {/*  <StyledInput*/}
            {/*    value={formData.businessRegistrationIdentifier}*/}
            {/*    onChange={handleChange("businessRegistrationIdentifier")}*/}
            {/*    placeholder="Enter Business Registration Identifier"*/}
            {/*  />*/}
            {/*</StyledInputGroup>*/}
            {/*<StyledInputGroup>*/}
            {/*  <StyledLabel>Business Identity</StyledLabel>*/}
            {/*  <StyledInput*/}
            {/*    value={formData.businessIdentity}*/}
            {/*    onChange={handleChange("businessIdentity")}*/}
            {/*    placeholder="Enter Business Identity"*/}
            {/*  />*/}
            {/*</StyledInputGroup>*/}
            {/*<StyledInputGroup>*/}
            {/*  <StyledLabel>Business Industry</StyledLabel>*/}
            {/*  <StyledInput*/}
            {/*    value={formData.businessIndustry}*/}
            {/*    onChange={handleChange("businessIndustry")}*/}
            {/*    placeholder="Enter Business Industry"*/}
            {/*  />*/}
            {/*</StyledInputGroup>*/}
            <StyledInputGroup>
              <StyledLabel>Business Registration Number/EIN (Please do not include any dashes or spaces)</StyledLabel>
              <StyledInput
                value={formData.businessRegistrationNumber}
                onChange={handleChange("businessRegistrationNumber")}
                placeholder="Enter Business Registration Number"
              />
            </StyledInputGroup>

            {/*<StyledInputGroup>*/}
            {/*  <StyledLabel>Friendly Name</StyledLabel>*/}
            {/*  <StyledInput*/}
            {/*    value={formData.friendlyName}*/}
            {/*    onChange={handleChange("friendlyName")}*/}
            {/*    placeholder="Enter Friendly Name"*/}
            {/*  />*/}
            {/*</StyledInputGroup>*/}
            {/*<StyledInputGroup>*/}
            {/*  <StyledLabel>Policy SID</StyledLabel>*/}
            {/*  <StyledInput*/}
            {/*    value={formData.policySid}*/}
            {/*    onChange={handleChange("policySid")}*/}
            {/*    placeholder="Enter Policy SID"*/}
            {/*  />*/}
            {/*</StyledInputGroup>*/}
            {/*<StyledInputGroup>*/}
            {/*  <StyledLabel>Status</StyledLabel>*/}
            {/*  <StyledInput value={formData.status} onChange={handleChange("status")} placeholder="Enter Status" />*/}
            {/*</StyledInputGroup>*/}
            {/*<StyledInputGroup>*/}
            {/*  <StyledLabel>Job Position</StyledLabel>*/}
            {/*  <StyledInput*/}
            {/*    value={formData.jobPosition}*/}
            {/*    onChange={handleChange("jobPosition")}*/}
            {/*    placeholder="Enter Job Position"*/}
            {/*  />*/}
            {/*</StyledInputGroup>*/}
            <StyledInputGroup>
              <StyledLabel>CEO First Name</StyledLabel>
              <StyledInput
                value={formData.firstName}
                onChange={handleChange("firstName")}
                placeholder="Enter First Name"
              />
            </StyledInputGroup>
            <StyledInputGroup>
              <StyledLabel>CEO Last Name</StyledLabel>
              <StyledInput
                value={formData.lastName}
                onChange={handleChange("lastName")}
                placeholder="Enter Last Name"
              />
            </StyledInputGroup>
            <StyledInputGroup>
              <StyledLabel>CEO Phone Number</StyledLabel>
              <StyledInput
                value={formData.phoneNumber}
                onChange={handleChange("phoneNumber")}
                placeholder="Enter Phone Number"
              />
            </StyledInputGroup>

            <StyledInputGroup>
              <StyledLabel>Company Email</StyledLabel>
              <StyledInput value={formData.email} onChange={handleChange("email")} placeholder="Enter Email" />
            </StyledInputGroup>
            {/*<StyledInputGroup>*/}
            {/*  <StyledLabel>Business Title</StyledLabel>*/}
            {/*  <StyledInput*/}
            {/*    value={formData.businessTitle}*/}
            {/*    onChange={handleChange("businessTitle")}*/}
            {/*    placeholder="Enter Business Title"*/}
            {/*  />*/}
            {/*</StyledInputGroup>*/}
            <StyledInputGroup>
              <StyledLabel>City for Mailing Address</StyledLabel>
              <StyledInput value={formData.city} onChange={handleChange("city")} placeholder="Enter City" />
            </StyledInputGroup>
            <StyledInputGroup>
              <StyledLabel>Customer Name for Mailing (repeat if same as official name)</StyledLabel>
              <StyledInput
                value={formData.customerName}
                onChange={handleChange("customerName")}
                placeholder="Enter Customer Name"
              />
            </StyledInputGroup>
            <StyledInputGroup>
              <StyledLabel>ISO Country (i.e., US)</StyledLabel>
              <StyledInput
                value={formData.isoCountry}
                onChange={handleChange("isoCountry")}
                placeholder="Enter ISO Country"
              />
            </StyledInputGroup>
            <StyledInputGroup>
              <StyledLabel>Postal Code</StyledLabel>
              <StyledInput
                value={formData.postalCode}
                onChange={handleChange("postalCode")}
                placeholder="Enter Postal Code"
              />
            </StyledInputGroup>
            <StyledInputGroup>
              <StyledLabel>Region (2 Letter State Code, i.e. NY)</StyledLabel>
              <StyledInput value={formData.region} onChange={handleChange("region")} placeholder="Enter Region" />
            </StyledInputGroup>
            <StyledInputGroup>
              <StyledLabel>Street Address (i.e. 1600 Pennsylvania Avenue)</StyledLabel>
              <StyledInput value={formData.street} onChange={handleChange("street")} placeholder="Enter Street" />
            </StyledInputGroup>
            <StyledInputGroup>
              <StyledLabel>Street Secondary (i.e. Appt 310)</StyledLabel>
              <StyledInput
                value={formData.streetSecondary}
                onChange={handleChange("streetSecondary")}
                placeholder="Enter Street Secondary"
              />
            </StyledInputGroup>
            {/*<StyledInputGroup>*/}
            {/*  <StyledLabel>Address SID</StyledLabel>*/}
            {/*  <StyledInput*/}
            {/*    value={formData.addressSid}*/}
            {/*    onChange={handleChange("addressSid")}*/}
            {/*    placeholder="Enter Address SID"*/}
            {/*  />*/}
            {/*</StyledInputGroup>*/}
          </div>
        </ModalBody>
        <ModalFooter>
          <StyledButton onClick={handleSubmit}>Submit</StyledButton>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default DataCollection;
