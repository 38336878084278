import styled, { device } from "@doar/shared/styled";
import { Text } from "@doar/components";

export const StyledText = styled(Text)`
    margin-top: 0px;
    font-weight: 400;
    ${device.mobile } {
        font-size: 16px;
    }
    ${device.nonMobile } {
        font-size: 14px;
    }
    overflow-wrap: break-word; 
    word-break: break-word;
    line-height: 1.3;
`;
