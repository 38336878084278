import styled, { device } from "@doar/shared/styled";
import { Select } from "@doar/components";


export const StyledSelect = styled(({ ...rest }) => <Select {...rest} />)`
  border-radius: 2rem;
  margin-left: 10px;
  max-width: 80px;
  box-shadow: none;
  margin-right: 0px;
  font-size: 12px;
  display: inline-block;
  &:disabled {
    color: #7987a1;
  }
  margin-top: 0;
  float: right;
  font-style: normal;

  /* Removes the default arrow in Chrome, Safari, and Opera */
  -webkit-appearance: none;
  /* Removes the default arrow in Firefox */
  -moz-appearance: none;
  /* Removes the default arrow in Edge */
  appearance: none;
  /* Removes the default arrow in IE and Edge */
  background-image: none;

 . text-align: center; /* horizontally centers the text in some browsers */

  /* In modern browsers, this ensures the selected option text is centered */
  text-align-last: center; 
`;

export const Content = styled.div`
    font-size: 12px;
    font-style: italic;
    margin-top: 5px;
    line-height: 1.5;

    ${device.mobile} {
        color: black;
    }
    ${device.nonMobile} {
        color: #aaaaaa;
    }
`;

export const Logo = styled(({ ...rest }) => <img {...rest} />)`
    height: 25px;
    background-color: #ffffff;
    padding: 2px;
    margin-left: 2px;
`;
