import styled from "@doar/shared/styled";
import { ModalHeader, Button, ModalFooter } from "@doar/components";
export const CenteredModalHeader = styled(({ ...rest }) => (
    <ModalHeader {...rest} />
))`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
`;

export const CenteredFooter = styled(({ ...rest }) => (
    <ModalFooter {...rest} />
))`
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    border-top: 0;
    font-size: 16px;
`;

export const StyledButton = styled(({ ...rest }) => <Button {...rest} />)`
    width: 5rem;
    border: 1px solid black;
    font-weight: bold;
    font-size: 16px;
`;
