import {
  ArrowContainer,
  EnhancedCard,
  OuterCard,
  StyledContainer,
  StyledLeftSection,
  StyledListingName,
  StyledMetaInfo,
  StyledMiddleSection,
  StyledNavItem,
  StyledNavItemContent,
  StyledRecentReviewsCard,
  StyledReviewList,
  StyledRightSection,
  StyledTaskCount,
} from "../../../components/tasks/main/style";
import { CardBody } from "@doar/components";
import React, { useCallback, useEffect, useState } from "react";
import ReservationSummary from "../../../components/messages/reservation-summary";
import MessageArea from "../../messages/message-area";
import { Card } from "antd";
import { hideTimeUpdates, sendTimeUpdateToPms } from "../../../api";

const convertMilitaryToStandardTime = (militaryTime) => {
  const [hours, minutes] = militaryTime.split(":").map(Number);
  const isPM = hours >= 12;
  const standardHours = hours % 12 || 12; // Converts 0 or 12+ to 12-hour format
  const period = isPM ? "PM" : "AM";

  return `${standardHours}:${String(minutes).padStart(2, "0")} ${period}`;
};

const prettifyFromToDate = (fromDate, toDate) => {
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
  function formatDate(dateString) {
    const [year, month, day] = dateString.split("-").map((str) => parseInt(str, 10));
    return `${monthNames[month - 1]} ${day}`;
  }
  const fromDateString = formatDate(fromDate);
  const toDateString = formatDate(toDate);
  const fromYear = fromDate.split("-")[0];
  const toYear = toDate.split("-")[0];
  if (fromYear === toYear) {
    if (fromDate.substring(0, 7) === toDate.substring(0, 7)) {
      return `${fromDateString}-${toDateString.split(" ")[1]}, ${toYear}`;
    } else {
      return `${fromDateString}, ${fromYear} - ${toDateString}, ${toYear}`;
    }
  } else {
    return `${fromDateString}, ${fromYear} - ${toDateString}, ${toYear}`;
  }
};

const LeftListItem = ({ item, setSelectedConvoID, setIdx, index, selectedIndex, setTimeUpdateData }) => {
  const handleClick = useCallback(() => {
    setIdx(index);
    setSelectedConvoID(item.hostaway_conversation_id);
  }, [index, item.hostaway_conversation_id, setIdx, setSelectedConvoID]);

  console.log("Rendering item: ", item);

  return (
    <StyledNavItem
      onClick={handleClick}
      style={{ backgroundColor: index === selectedIndex ? "rgba(72,94,144,0.16)" : "rgba(255,255,255,0.08)" }}
    >
      <StyledNavItemContent>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div>
            <StyledListingName style={{ fontWeight: "bold" }}>{item.guest_name}</StyledListingName>
            <div style={{ fontSize: "10px", color: "rgba(72,94,144,0.64)" }}>
              {item.name} -{prettifyFromToDate(item.arrival_date, item.departure_date)}
            </div>
          </div>
        </div>
        <StyledMetaInfo style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <StyledTaskCount>{item.possibleChanges}</StyledTaskCount>
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              color: "rgba(72,94,144,0.64)",
              fontSize: "16px",
              cursor: "pointer",
            }}
            onClick={async (e) => {
              hideTimeUpdates({ reservationId: item.hostaway_reservation_id, pms: item.pms });
              // Can a node kill itself in react?
              setTimeUpdateData((prevState) => {
                return prevState.filter((el) => el.hostaway_reservation_id !== item.hostaway_reservation_id);
              });
            }}
          >
            X
          </button>
        </StyledMetaInfo>
      </StyledNavItemContent>
    </StyledNavItem>
  );
};

// LeftListItem.displayName = "LeftListItem";

const TimeUpdateCards = ({ timeUpdates, convoIndex, isEditablePms }) => {
  const [renderList, setRenderList] = useState([]);
  const [userId, setUserId] = useState(null);
  const [reservationId, setReservationId] = useState(null);

  useEffect(() => {
    if (convoIndex === null) return;
    console.log(timeUpdates);
    const item = timeUpdates[convoIndex];
    console.log("item: ", item);

    // Create a local list
    let newList = [];

    // Handle Checkin
    if (item.is_early_check_in || item.is_late_check_in) {
      newList.push({
        legalTime: item.legal_check_in_time,
        expectedTime: item.expected_check_in_time,
        description: item.check_in_description,
        title: item.isEarlyCheckIn ? "Early Check-In Request" : "Late Check-In Announcement",
        isCheckinItem: true,
        pmsTime: item.pms_check_in_time,
        sentToPms: false,
      });
    }

    // Handle Checkout
    if (item.is_early_check_out || item.is_late_check_out) {
      newList.push({
        legalTime: item.legal_check_out_time,
        expectedTime: item.expected_check_out_time,
        description: item.check_out_description,
        title: item.isLateCheckOut ? "Late Check-Out Request" : "Early Check-Out Announcement",
        isCheckinItem: false,
        pmsTime: item.pms_check_out_time,
        sentToPms: false,
      });
    }

    // Update renderList with the new entries
    setRenderList(newList);

    setUserId(item.user_id);
    setReservationId(item.hostaway_reservation_id);
  }, [convoIndex, timeUpdates]);

  return (
    <EnhancedCard>
      {renderList
        ? renderList.map((item, idx) => {
            const checkWord = item.isCheckinItem ? "Check-in" : "Check-out";
            return (
              <Card key={idx} style={{ marginBottom: "10px" }}>
                <CardBody style={{ padding: "10px", display: "flex", flexDirection: "column" }}>
                  <div style={{ margin: "auto", fontWeight: "bold" }}>{item.title}</div>
                  <div
                    style={{
                      margin: "auto",
                      width: "100%",
                      textAlign: "center",
                      borderBottom: "1px solid #000",
                      borderTop: "1px solid #000",
                    }}
                  >
                    {item.description}
                  </div>
                  <div style={{ margin: "auto", width: "100%" }}>
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        marginTop: "20px",
                      }}
                    >
                      <span style={{ fontWeight: "bold", textAlign: "left", width: "40%" }}>
                        Default Listing {checkWord} time:{" "}
                      </span>
                      <span>{convertMilitaryToStandardTime(item.legalTime)}</span>
                    </span>
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        marginTop: "10px",
                      }}
                    >
                      <span style={{ fontWeight: "bold", textAlign: "left", width: "40%" }}>
                        Detected {checkWord} Time:
                      </span>
                      <input
                        type="time"
                        value={item.expectedTime}
                        style={{
                          border: "1px solid #d1d5db",
                          borderRadius: "4px",
                          padding: "5px",
                          fontSize: "14px",
                          width: "50%",
                        }}
                        onChange={(e) => {
                          if (!isEditablePms) return;
                          const updatedTime = e.target.value;
                          setRenderList((prevList) =>
                            prevList.map((el, idx) =>
                              idx === renderList.indexOf(item) ? { ...el, expectedTime: updatedTime } : el
                            )
                          );
                        }}
                        disabled={!isEditablePms}
                      />
                    </span>
                    {/*<div style={{ display: "flex", width: "100%" }}>*/}
                    {/*  {item.pmsTime ? (*/}
                    {/*    <span*/}
                    {/*      style={{*/}
                    {/*        display: "flex",*/}
                    {/*        justifyContent: "space-between",*/}
                    {/*        alignItems: "center",*/}
                    {/*        width: "100%",*/}
                    {/*        marginTop: "20px",*/}
                    {/*      }}*/}
                    {/*    >*/}
                    {/*      <span style={{ fontWeight: "bold", textAlign: "left", width: "40%" }}>PMS time:</span>*/}
                    {/*      <span>{item.pmsTime}</span>*/}
                    {/*    </span>*/}
                    {/*  ) : null}*/}
                    {/*</div>*/}
                    <div style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center" }}>
                      {isEditablePms && (
                        <button
                          style={{
                            margin: "auto",
                            backgroundColor: "#0168fa",
                            color: "#fff",
                            width: "80%",
                            height: "30px",
                            marginTop: "10px",
                            borderRadius: "5px",
                            border: "none",
                          }}
                          onClick={async () => {
                            await sendTimeUpdateToPms({
                              user_id: userId,
                              reservation_id: reservationId,
                              new_time: item.expectedTime,
                              isCheckinUpdate: item.isCheckinItem,
                            });
                            setRenderList((prevList) =>
                              prevList.map((el, idx) =>
                                idx === renderList.indexOf(item) ? { ...el, sentToPms: true } : el
                              )
                            );
                          }}
                        >
                          Set {checkWord} Time in PMS to {convertMilitaryToStandardTime(item.expectedTime)}
                        </button>
                      )}
                      {item.sentToPms && (
                        <span style={{ color: "green", marginTop: "5px", fontSize: "12px" }}>Data sent to PMS</span>
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            );
          })
        : null}
    </EnhancedCard>
  );
};

const TimeAdjustments = ({ timeUpdates, convoId, setConvoId, setTimeUpdateData, isEditablePms }) => {
  const [loading, setLoading] = useState(false);
  const [convoIndex, setConvoIndex] = useState(0);
  const [messageIndex, setMessageIndex] = useState(0);

  console.log("Convo Index: ", convoIndex);

  useEffect(() => {
    const newMessageIndex = (timeUpdates[convoIndex] && timeUpdates[convoIndex].check_in_message_index) || 0;
    setMessageIndex(newMessageIndex);
  }, [convoIndex, timeUpdates]);

  return (
    <ArrowContainer>
      <OuterCard style={{ border: "none" }}>
        <StyledContainer style={{ height: "80vh" }}>
          <StyledLeftSection>
            <StyledRecentReviewsCard>
              <CardBody style={{ padding: "0px" }}>
                <StyledReviewList style={{ overflowY: "auto", overflowX: "hidden" }}>
                  {/* Only show tasks that match selected status */}
                  {timeUpdates
                    ? timeUpdates.map((item, idx) => (
                        <LeftListItem
                          key={idx}
                          item={item}
                          setSelectedConvoID={setConvoId}
                          index={idx}
                          selectedIndex={convoIndex}
                          setIdx={setConvoIndex}
                          setTimeUpdateData={setTimeUpdateData}
                        />
                      ))
                    : null}
                </StyledReviewList>
              </CardBody>
            </StyledRecentReviewsCard>
          </StyledLeftSection>
          <StyledMiddleSection>
            <ReservationSummary style={{ visibility: loading || !convoId ? "hidden" : "visible" }} />
            <MessageArea
              fullHeight={true}
              loading={loading}
              style={{ border: "1px solid rgba(72,94,144,0.16)", visibility: !convoId ? "hidden" : "visible" }}
              messageIdx={messageIndex}
            />
          </StyledMiddleSection>
          <StyledRightSection>
            <TimeUpdateCards timeUpdates={timeUpdates} convoIndex={convoIndex} isEditablePms={isEditablePms} />
          </StyledRightSection>
        </StyledContainer>
      </OuterCard>
    </ArrowContainer>
  );
};

export default TimeAdjustments;
