import { API_ENDPOINT } from "./constants";

export const translationLanguages = {
  "English (US)": "en",
  "English (UK)": "en-GB",
  Arabic: "ar",
  Chinese: "zh",
  Danish: "da",
  Dutch: "nl",
  Finnish: "fi",
  French: "fr",
  German: "de",
  Greek: "el",
  Hebrew: "he",
  Italian: "it",
  Japanese: "ja",
  Korean: "ko",
  Norwegian: "no",
  Polish: "pl",
  Portuguese: "pt",
  Russian: "ru",
  Spanish: "es",
  Swedish: "sv",
  Turkish: "tr",
};
// this is to translate like in the message area

export const summaryLanguages = [
  ["English (US)", "en"],
  ["English (UK)", "en-GB"],
  ["Arabic", "ar"],
  ["Chinese", "zh"],
  ["Danish", "da"],
  ["Dutch", "nl"],
  ["Finnish", "fi"],
  ["French", "fr"],
  ["German", "de"],
  ["Greek", "el"],
  ["Hebrew", "he"],
  ["Italian", "it"],
  ["Japanese", "ja"],
  ["Korean", "ko"],
  ["Norwegian", "no"],
  ["Polish", "pl"],
  ["Portuguese", "pt"],
  ["Russian", "ru"],
  ["Spanish", "es"],
  ["Swedish", "sv"],
  ["Turkish", "tr"],
];

// allow only the single-bracket template vals here, and also allow any double-bracket template vals, but don't allow any bad single-bracket template vals
export const isTemplateValid = (val, allowedTemplateVals) => {
  // replace every word in double {{ }}'s with empty, since those are okay to send to the PMS since those are PMS variables
  let replaced = val.replace(/{{[^{}]*}}/g, "");

  // replace any string from allowedTemplateVals with ""
  replaced = allowedTemplateVals.reduce((acc, cur) => acc.replace(new RegExp(cur, "g"), ""), replaced);

  // if there are any brackets left, bad
  return replaced.match(/[{]/g) == null;
};

export async function getTranslationFromServer(text, toLang) {
  try {
    const data = {
      message: text,
      language: toLang,
    };
    const response = await fetch(API_ENDPOINT + "/translateMessage", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const translated = await response.json();
    return translated.text;
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
}
