import { HostingLogo } from "./style";


/** DO NOT DELETE THIS COMMENT 
 * 
 * FILL OUT BELOW DICTIONARIES FOR NEW PMS INTEGRATIONS!! 
 * 
 * Please enter the channel_names for your PMS in lower case because they are converted to lower-case when we do the comparisons. 
 * 
 * It's laid out as dictionaries mapping to arrays because it makes it easier to see which PMSes need to be added even if it means names that are used by multiple 
 * PMSes are duplicated (like 'airbnb'). For the purposes of our code, the duplicates do not matter.
 * 
 * If your PMS has a channel_name that corresponds to your PMS (for example Uplisting has a channel_name called "uplisting") add a case directly to the return statement
 * below in HostServiceIcon and add a link to your PMS's logo (follow the example of guesty, hostaway, and uplisting).
 * 
 * Make sure that you put a logo for any new channels in 'bestyDashboard/packages/main/public/logos'
 * 
*/

// Lists the various channel_names (converted to lower case) that correspond to Airbnb that each PMS stores in our reservation table 
const airbnbNames = {
    "hostaway": ["airbnbofficial", "airbnb"],
    "guesty": ["airbnb2", "airbnb"],
    "uplisting": ["airbnb_official"],
    "lodgify": ["airbnb"],
    "ownerrez": ["airbnb"],
    "hostfully": ["airbnb"]
};
// Lists the various channel_names (converted to lower case) that correspond to booking.com that each PMS stores in our reservation table 
const bookingComNames = {
    "hostaway": ["bookingcom"],
    "guesty": ["bookingcom"],
    "uplisting": ["booking_dot_com"],
    "lodgify": ["bookingcom"],
    "ownerrez": ["booking.com"],
    "hostfully": ["bookingcom"]
};
// Lists the various channel_names (converted to lower case) that correspond to VRBO that each PMS stores in our reservation table 
const vrboNames = {
    "hostaway": ["vrboical"],
    "guesty": [],
    "uplisting": ["vrbo"],
    "lodgify": [],
    "ownerrez": ["vrbo"],
    "hostfully": ["vrbo"],
    "escapia": ["vrbo/homeaway"]
};
// Lists the various channel_names (converted to lower case) that correspond to Home Away that each PMS stores in our reservation table 
const homeAwayNames = {
    "hostaway": ["homeaway", "homeawayical"],
    "guesty": ["homeaway2"],
    "uplisting": ["home_away"],
    "lodgify": ["homeaway"],
    "ownerrez": [],
    "hostfully": []
};
// Lists the various channel_names (converted to lower case) that correspond to Expedia that each PMS stores in our reservation table 
const expediaNames = {
    "hostaway": ["expedia"],
    "guesty": ["expedia"],
    "uplisting": [],
    "lodgify": ["expedia"],
    "ownerrez": [],
    "hostfully": []
};

const airbnbSet = new Set(Object.values(airbnbNames).flatMap(name => name));
const bookingComSet = new Set(Object.values(bookingComNames).flatMap(name => name));
const vrboSet = new Set(Object.values(vrboNames).flatMap(name => name));
const homeAwaySet = new Set(Object.values(homeAwayNames).flatMap(name => name));
const expediaSet = new Set(Object.values(expediaNames).flatMap(name => name));

const HostingServiceIcon = ({ hostingService, style }) => {
    style ||= {};
    const hostingServiceDowncase = hostingService.toLowerCase() + "";
    //console.log('HostingServiceIcon called with:', hostingServiceDowncase);
    return (<>
        {
            hostingServiceDowncase === "guesty" ? <HostingLogo style={style} src="/logos/guestyIcon.png" alt="Guesty" /> :
                hostingServiceDowncase === "hostaway" ? <HostingLogo style={style} src="/logos/hostawayIcon.png" alt="Hostaway" /> :
                    hostingServiceDowncase === "uplisting" ? <HostingLogo style={style} src="/logos/uplisting.png" alt="Uplisting" /> :
                        hostingServiceDowncase === "escapia" ? <HostingLogo style={style} src="/logos/escapiaLogo.png" alt="Escapia" /> :
                            airbnbSet.has(hostingServiceDowncase) ? <HostingLogo style={style} src="/logos/airbnb.png" alt="Airbnb" /> :
                                bookingComSet.has(hostingServiceDowncase) ? <HostingLogo style={style} src="/logos/booking.png" alt="Booking.com" /> :
                                    vrboSet.has(hostingServiceDowncase) ? <HostingLogo style={style} src="/logos/vrbo.png" alt="VRBO" /> :
                                        homeAwaySet.has(hostingServiceDowncase) ? <HostingLogo style={style} src="/logos/homeaway.png" alt="Homeaway" /> :
                                            expediaSet.has(hostingServiceDowncase) ? <HostingLogo style={style} src="/logos/expedia.png" alt="Expedia" /> :
                                                <HostingLogo style={style} src="/logos/directBooking.png" alt="Direct Booking" />
        }
    </>);

};

export default HostingServiceIcon;