import { Content, StyledSelect } from "./style";
import { parseISO, format } from "date-fns";
import { useState } from "react";

const Metadata = ({ timestamp, prefix, languages, selectedLanguage, setSelectedLanguage }) => {
    const parsed = timestamp ? parseISO(timestamp) : null;
    const time = timestamp ? format(parsed, "h:mm aa") : '';

    //const date = format(parsed, "yyyy/MM/dd");
    const prettifyDate = (parsed) => {
      // if timestamp is today, return "today"
      // if timestamp is yesterday, return "yesterday"
      // if timestamp is this year, return "month/day"
      // else, write "year/month/day"

      // https://date-fns.org/v2.30.0/docs/format

      const date = format(parsed, "LLL d, yyyy");
      const today = format(new Date(), "LLL d, yyyy");
      const yesterday = format(new Date(Date.now() - 86400000), "LLL d, yyyy");
      const year = format(parsed, "yyyy");
      const thisYear = format(new Date(), "yyyy");
      const monthDay = format(parsed, "LLL d");
      if (date === today) {
        return " today";
      }
      if (date === yesterday) {
        return " yesterday";
      }
      if (year === thisYear) {
        return ` on ${monthDay}`;
      }
      return ` on ${date}`;
    };


    return (
        <>
            <Content>
                {timestamp ? `${prefix} ${prettifyDate(parsed)} at ${time}` : ''}

                {languages?.length && (
                    <StyledSelect
                        value={selectedLanguage}
                        onChange={(e) => setSelectedLanguage(e.target.value)}
                >
                    {languages.map(lang => (
                        <option key={lang.value} value={lang.value}>
                            {lang.label}
                        </option>
                        ))}
                    </StyledSelect>
                )}
            </Content>
        </>
    );

};

export default Metadata;
