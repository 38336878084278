import { MediaBody } from "@doar/components";
import { MediaContainer, StyledMedia, StyledHeading, AvatarDiv } from "./style";
import Metadata from "../metadata";
import MessageText from "../message-text";

const MessageCard = ({ text, pms, hostingService, sender, timestamp, languages, selectedLanguage, setSelectedLanguage }) => {
    return (
        <StyledMedia>
            <MediaContainer>
                <MediaBody >
                    <MessageText {...{ text }} />
                    <Metadata
                        timestamp={timestamp}
                        pms={pms}
                        hostingService={hostingService}
                        prefix={sender}
                        languages={languages}
                        selectedLanguage={selectedLanguage}
                        setSelectedLanguage={setSelectedLanguage}
                    />
                </MediaBody>
            </MediaContainer>
        </StyledMedia>
    );
};


export default MessageCard;
