import { useAppSelector } from "src/redux/hooks";
import { getMessageStatusFetching, getSelectedThread } from "src/redux/slices/messages";
import { StyledArea, DateArea, StatusArea, DeleteButton } from "./style";
import HostingServiceIcon from "src/components/messages/hosting-service-icon";
import { X } from "react-feather";
import ArchiveChatModal from "src/components/messages/archive-chat-modal";
import { useState } from "react";

const ReservationSummary = ( {style} ) => {
    const STATUS_TO_NAME = {
      'new': 'Reservation',
      'modified': 'Reservation'
    };
    const isNotAlreadyArchived = useAppSelector(getMessageStatusFetching) !== "Archived";

    function camelCaseToText(str){
      if (str) {
        return str
          .replace(/([A-Z])/g, ' $1')
          .replace(/^./, (s) => s.toUpperCase());
      } else {
        return ""
      }
    }
    const prettifyReservationStatus = (status) => {
      status = STATUS_TO_NAME[status] || status;
      return camelCaseToText(status);
    };

    const selectedThread = useAppSelector(getSelectedThread);
    const [showModal, setShowModal] = useState(false);

    // prettify from and to date. thanks chatgpt
    // {/*selectedThread.reservationStart} to {selectedThread.reservationEnd*/}
    const prettifyFromToDate = (fromDate, toDate) => {
      const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
      function formatDate(dateString) {
        const [year, month, day] = dateString.split('-').map(str => parseInt(str, 10));
        return `${monthNames[month - 1]} ${day}`;
      }
      const fromDateString = formatDate(fromDate);
      const toDateString = formatDate(toDate);
      const fromYear = fromDate.split('-')[0];
      const toYear = toDate.split('-')[0];
      if (fromYear === toYear) {
        if (fromDate.substring(0, 7) === toDate.substring(0, 7)) {
          return `${fromDateString}-${toDateString.split(' ')[1]}, ${toYear}`;
        } else {
          return `${fromDateString}, ${fromYear} - ${toDateString}, ${toYear}`;
        }
      } else {
        return `${fromDateString}, ${fromYear} - ${toDateString}, ${toYear}`;
      }
    };

    try {
      return (
          <StyledArea style={style}>
              <strong>
                  {selectedThread.guestName} | {selectedThread.listingName}
              </strong>
              <br/>
              <StatusArea>
                  <HostingServiceIcon style={{margin: "-1px -1px 0px 1px", height: "10px"}} hostingService={selectedThread.hostingService} />&nbsp;
                  {prettifyReservationStatus(selectedThread.reservationStatus)} |&nbsp;
                  {prettifyFromToDate(selectedThread.reservationStart, selectedThread.reservationEnd)}
              </StatusArea>

              {isNotAlreadyArchived && <DeleteButton
                  onClick={() => setShowModal(true)}
                  variant="texted"
                  size="md"
                  shape="rounded"
              >
                  <X size={18} />
              </DeleteButton>}
              <ArchiveChatModal {...{ showModal, setShowModal }} />

          </StyledArea>
      );
    } catch (error) {
      return ""
    }

    /*
    return (
        <StyledArea>
            <strong>
                {selectedThread.guestName} | {selectedThread.listingName}
            </strong>
            <br/>
            <DateArea>
                {selectedThread.reservationStart} to {selectedThread.reservationEnd}
            </DateArea>
            <StatusArea>
                {prettifyReservationStatus(selectedThread.reservationStatus)}
            </StatusArea>
        </StyledArea>
    );
    */

};

export default ReservationSummary;
