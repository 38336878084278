import styled, { device } from "@doar/shared/styled";
import { Media, Heading } from "@doar/components";

export const StyledMedia = styled(Media)`
    border: none;
    width: 100%;
`;

/*
    ${device.large} {
        max-width: 65%;
    }
    ${device.medium} {
        max-width: 75%;
    }
    ${device.small} {
        max-width: 100%;
    }
    border: 1px solid rgba(72,94,144,0.16);
    */
export const MediaContainer = styled.div`
    border-radius: 0.25rem;
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
    margin-top: 10px;
    border: none;
    background-color: rgba(217,217,217,0.38);
    color: black;

    ${device.nonMobile} {
        max-width: 70%;
        padding: 15px;
    }

    ${device.mobile} {
        max-width: 90%;
        padding: 10px;
    }
`;

export const StyledHeading = styled(({ ...rest }) => (
    <Heading {...{ mb: "0px", ...rest }} />
))`
    font-weight: 600;
    font-size: 14px;
    line-height: 1.5;
`;

export const AvatarDiv = styled.div`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    position: relative;
`;

/*
export const StyledMedia = styled(Media)`
    border: none;
    ${device.large} {
        max-width: 65%;
    }
    ${device.medium} {
        max-width: 75%;
    }
    ${device.small} {
        max-width: 100%;
    }

    display: flex;
    flex-direction: column;
`;

export const StyledHeading = styled(({ ...rest }) => (
    <Heading {...{ mb: "5px", ...rest }} />
))`
    font-weight: 600;
    font-size: 14px;
`;

export const AvatarDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
`;
*/
