import { createSlice } from "@reduxjs/toolkit";
import { AutopilotRule, AutopilotSettings } from "src/api";
import { RootState } from "src/redux/store";
import { v4 as uuidv4 } from "uuid";

export const DEFAULT_SETTINGS: Partial<AutopilotSettings> = {
  rules: [
    {
      id: uuidv4(),
      name: "Autopilot Rule 1",
      listingSetIds: [],
      config: {
        prefix: "",
        prefixEnabled: false,
        cooloff: 5,
        enabled: false,
        is247: true,
        isAllListings: true,
        lowerTime: "01:00:00-00",
        upperTime: "01:00:00-00",
        isWeekendMode: false,
        weekdayLowerTime: "01:00:00-00",
        weekdayUpperTime: "01:00:00-00",
        weekendLowerTime: "01:00:00-00",
        weekendUpperTime: "01:00:00-00",
        listings: [],
        guestMode: "all",
        escalateMessage: "",
        escalateMessageEnabled: false,
        escalateMessageSequence: [],
        escalateMessageSequenceEnabled: false,
        prefixMode: "all",
        delay: 0,
        fetched: false,
      },
    },
  ],
};

export interface GlobalState {
  autopilotSettings: AutopilotSettings;
}

const initialState: GlobalState = {
  autopilotSettings: {
    prefixEnabled: false,
    prefix: "",
    enabled: false,
    is247: true,
    isAllListings: true,
    lowerTime: "01:00:00-00",
    upperTime: "01:00:00-00",
    isWeekendMode: false,
    weekdayLowerTime: "01:00:00-00",
    weekdayUpperTime: "01:00:00-00",
    weekendLowerTime: "01:00:00-00",
    weekendUpperTime: "01:00:00-00",
    listings: [],
    cooloff: 5,
    guestMode: "all",
    escalateMessageEnabled: false,
    escalateMessage: "",
    escalateMessageSequenceEnabled: false,
    escalateMessageSequence: ["", "", ""],
    prefixMode: "all",
    delay: 0,
    fetched: false,
    rules: DEFAULT_SETTINGS.rules as AutopilotRule[],
    listingOption: "All Listings",
  },
};

const autopilotSettingsSlice = createSlice({
  name: "autopilotSettings",
  initialState,
  reducers: {
    setAutopilotSettings: (state, action) => {
      state.autopilotSettings = {
        ...state.autopilotSettings,
        ...action.payload,
        fetched: true,
      };
    },
  },
});

export const { setAutopilotSettings } = autopilotSettingsSlice.actions;

export const getAutopilotSettings = (state: RootState) => {
  if (state.autopilotSettings.autopilotSettings.rules.length === 0) {
    return {
      ...state.autopilotSettings.autopilotSettings,
      rules: DEFAULT_SETTINGS.rules as AutopilotRule[],
    };
  }
  return state.autopilotSettings.autopilotSettings;
};

export default autopilotSettingsSlice.reducer;
