import { useEffect, useRef, useState } from "react";
import { LeftDiv, RightDiv, StyledArea } from "./style";
import { useAppSelector } from "src/redux/hooks";
import { getSelectedThread } from "src/redux/slices/messages";
import MessageCard from "src/components/messages/message-card";
import MessageCardRTL from "src/components/messages/message-card-rtl";
import Preloader from "src/components/preloader";
import { translationLanguages, getTranslationFromServer } from "src/api/";

const MessageArea = ({ fullHeight, loading, style, messageIdx }) => {
  const selectedThread = useAppSelector(getSelectedThread);
  const { thread, pms, hostingService } = selectedThread;
  const threadRef = useRef();
  const messageRefs = useRef([]);
  const [hasUserScrolled, setHasUserScrolled] = useState(false);
  const lastMessageIdxRef = useRef(messageIdx);

  let languages = Object.keys(translationLanguages).map((lang) => ({
    value: translationLanguages[lang],
    label: lang,
  }));
  languages.unshift({ value: "", label: "Translate" });

  const [messageTexts, setMessageTexts] = useState({});
  const [selectedLanguages, setSelectedLanguages] = useState({});

  useEffect(() => {
    if (messageIdx !== undefined && messageIdx !== lastMessageIdxRef.current) {
      setHasUserScrolled(false);
      lastMessageIdxRef.current = messageIdx;
    }

    if (messageIdx !== undefined && !hasUserScrolled && messageRefs.current[messageIdx]) {
      const messageElement = messageRefs.current[messageIdx];
      if (messageElement && threadRef.current) {
        const messageOffset = messageElement.offsetTop;
        const messageHeight = messageElement.offsetHeight;
        const viewHeight = threadRef.current.offsetHeight;

        if (messageHeight > viewHeight) {
          threadRef.current.scrollTop = messageOffset;
        } else {
          threadRef.current.scrollTop = messageOffset - viewHeight / 2 + messageHeight / 2;
        }
      }
    }

    setTimeout(() => {
      threadRef.current.scrollTop = threadRef.current.scrollHeight;
    }, 50);
  }, [thread, messageIdx, hasUserScrolled]);

  const handleScroll = () => {
    if (!hasUserScrolled) {
      setHasUserScrolled(true);
    }
  };

  const handleTranslation = async (text, messageId, newLanguage) => {
    console.log(`translating message "${text}" to ${newLanguage}`);
    setSelectedLanguages((prev) => ({
      ...prev,
      [messageId]: newLanguage,
    }));

    const translatedText = await getTranslationFromServer(text, newLanguage);

    setMessageTexts((prev) => ({
      ...prev,
      [messageId]: translatedText,
    }));
  };
  return (
    <StyledArea ref={threadRef} fullHeight={fullHeight} style={style} onScroll={handleScroll}>
      {loading ? (
        <Preloader style={{ position: "relative", width: "100%", height: "100%" }} />
      ) : (
        (thread ?? []).map((message, index) => {
          const { id, isGuest } = message;
          const messageId = id || index;
          const leftKey = `l${messageId}${index}`;
          const rightKey = `r${messageId}${index}`;

          const cardData = {
            ...message,
            languages,
            text: messageTexts[messageId] || message.text,
            selectedLanguage: selectedLanguages[messageId] || "",
            setSelectedLanguage: (newLanguage) => handleTranslation(message.text, messageId, newLanguage),
          };

          return isGuest ? (
            <LeftDiv ref={(el) => (messageRefs.current[index] = el)} key={leftKey}>
              <MessageCard {...{ pms, hostingService, ...cardData }} />
            </LeftDiv>
          ) : (
            <RightDiv ref={(el) => (messageRefs.current[index] = el)} key={rightKey}>
              <MessageCardRTL {...cardData} />
            </RightDiv>
          );
        })
      )}
    </StyledArea>
  );
};

export default MessageArea;
