import { createSlice } from "@reduxjs/toolkit";
import { ListingSet, ListingSetsSettings } from "src/api";
import { RootState } from "src/redux/store";
import { v4 as uuidv4 } from "uuid";

export interface ListingSetsSettingsState {
  listingSets: ListingSet[];
  enabled: boolean;
}

const initialState: ListingSetsSettingsState = {
  listingSets: [],
  enabled: false,
};

const listingSetsSettingsSlice = createSlice({
  name: "listingSetsSettings",
  initialState,
  reducers: {
    setListingSetsSettings: (state, action) => {
      Object.assign(state, action.payload);
    },
  },
});

export default listingSetsSettingsSlice.reducer;
export const { setListingSetsSettings } = listingSetsSettingsSlice.actions;

export const getListingSetsSettings = (state: RootState) => {
  const settings = state.listingSetsSettings;
  return settings?.listingSets?.length === 0 ? { ...settings, listingSets: null } : settings || DEFAULT_SETTINGS;
};
export const DEFAULT_SETTINGS: Partial<ListingSetsSettings> = {
  listingSets: [{ id: uuidv4(), name: "Listing Set 1", listingIds: [] }],
  enabled: false,
};
