import styled, { themeGet } from "@doar/shared/styled";
import { Card, Table, IProps as TableProps, ButtonGroup, Button, Select } from "@doar/components";

export const StyledCard = styled(Card)`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  margin: 0;
`;

export const StyledContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  padding: 0;
  overflow: hidden;
`;

export const StyledLeftSection = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-right: 10px;
  width: 35%;
  overflow: hidden;
`;
export const StyledRecentReviewsCard = styled(StyledCard)`
  margin-top: 10px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
`;

export const StyledReviewList = styled.div`
  overflow-y: auto;
  height: 100%;
  flex-grow: 1;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
export const StyledTaskCount = styled.span`
  font-size: 0.8em;
  background-color: ${themeGet("colors.primary")};
  color: white;
  border-radius: 10px;
  padding: 2px 6px;
  margin-left: 5px;
`;
export const StyledMiddleSection = styled.div`
  width: 75%;
  height: 100%;
  padding: 5px;
  display: flex;
  flex-direction: column;
`;

export const StyledRightSection = styled.div`
  width: 45%;
  height: 100%;
  padding: 5px;
  display: flex;
  flex-direction: column;
`;

export const StyledTable = styled(({ ...rest }) => <Table {...rest} />)`
  width: 100%;
  table-layout: fixed;

  td {
    padding: 10px;
    border-top: 1px solid ${(props) => props.theme.colors.border};
    word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
    vertical-align: top;
    white-space: normal;
  }

  tr:first-child td {
    border-top: none;
  }

  th,
  td {
    width: 100%;
  }
`;

export const StyledButtonGroup = styled(ButtonGroup)`
  display: flex;
  justify-content: flex-end;
  gap: 5px;
`;

export const SquareButton = styled(Button)`
  width: 24px;
  height: 24px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
`;

export const StyledNavItem = styled.div`
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 5px;
  background-color: ${(props) => (props.active ? themeGet("colors.primary") : "transparent")};
  color: ${(props) => (props.active ? themeGet("colors.white") : themeGet("colors.text"))};
  position: relative;

  &:hover {
    background-color: ${(props) => (props.active ? themeGet("colors.primary") : themeGet("colors.light"))};
  }
`;

export const StyledNavItemContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const StyledListingInfo = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  min-width: 0;
`;

export const StyledListingName = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
  margin-right: 10px;
`;

export const StyledMetaInfo = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

export const StyledTimeAgo = styled.span`
  font-size: 0.8em;
  opacity: 0.7;
  white-space: nowrap;
  margin-right: 5px;
`;

export const UnreadIndicator = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #ff8080;
  display: ${(props) => (props.unread ? "block" : "none")};
  flex-shrink: 0;
  margin-right: 5px;
`;

export const OuterCard = styled(Card)`
  width: 100%;
  height: 100%;
  overflow-y: hidden;
`;

export const ArrowContainer = styled.div`
  position: relative;
  width: 100%;
`;
export const ArrowButton = styled(Button)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;

  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: white;
  color: ${themeGet("colors.primary")};

  &:focus {
    background-color: ${themeGet("colors.primaryHover")};
  }

  &:hover {
    background-color: ${themeGet("colors.primary")};
  }
`;

export const EnhancedCard = styled(StyledCard)`
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: scroll;
`;

// Add this new styled component for the modal
export const SimpleModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 100%;
  max-width: 400px;
`;

export const ModalHeader = styled.h2`
  margin-top: 0;
  margin-bottom: 20px;
  text-align: center;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const StyledLabel = styled.label`
  font-weight: bold;
`;
export const StyledInput = styled.input`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
`;

export const StyledTextarea = styled.textarea`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  resize: vertical;
`;

export const StyledSelect = styled.select`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%23333' d='M10.293 3.293L6 7.586 1.707 3.293A1 1 0 00.293 4.707l5 5a1 1 0 001.414 0l5-5a1 1 0 10-1.414-1.414z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 12px;
`;

export const FilterSelect = styled(StyledSelect)`
  width: 100%;
`;
export const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 10px;
`;

export const ListingName = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: center;
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

export const FilterRow = styled.div`
  display: flex;
  gap: 10px;
`;

export const FilterColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

export const FilterTitle = styled.h5`
  margin-bottom: 5px;
  color: ${themeGet("colors.text")};
  font-size: 0.9rem;
  text-align: center;
`;
