import { Suspense, lazy, useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import useWebSocket from "react-use-websocket";
import { WEBSOCKET_URL, getUserInfo, getWebAccessTokensInitialized, setFirstTimeListingsReady } from "src/api";
import { useAppDispatch } from "src/redux/hooks";
import { setUserInfo } from "src/redux/slices/global";
import { setUnansweredMessages } from "src/redux/slices/messages";
import { getUser, tokenToUserId } from "src/utils/";
import Preloader from "./components/preloader";

// Lazy-loaded pages
const ReviewTasksPage = lazy(() => import("./pages/reviewTasks"));
const TasksPage = lazy(() => import("./pages/tasks"));
const ContentPage = lazy(() => import("./pages/messages"));
const WidgetMessages = lazy(() => import("./pages/widgetMessages"));
const Listings = lazy(() => import("./pages/listings"));
const Reviews = lazy(() => import("./pages/reviews"));
const Dashboard = lazy(() => import("./pages/dashboard"));
const Settings = lazy(() => import("./pages/settings"));
const Broadcast = lazy(() => import("./pages/broadcast"));
const Copilot = lazy(() => import("./pages/copilot"));
const Login = lazy(() => import("./pages/login"));
const ApiCreds = lazy(() => import("./pages/apiCreds"));
const ResetPassword = lazy(() => import("./pages/resetPassword"));
const Signup = lazy(() => import("./pages/signup"));
const Logout = lazy(() => import("./pages/logout"));
const Refer = lazy(() => import("./pages/refer"));
const FreeTrial = lazy(() => import("./pages/freeTrial"));
const UpgradePlan = lazy(() => import("./pages/upgradePlan"));
const CheckoutSuccess = lazy(() => import("./pages/checkoutSuccess"));
const CheckoutCancelled = lazy(() => import("./pages/checkoutCancelled"));
const PaymentFailed = lazy(() => import("./pages/paymentFailed"));
const AutomatedMessages = lazy(() => import("./pages/AutomatedMessages"));
import PrivateRoute from "./components/private-route";
import WebAccessInstructions from "./pages/webAccessInstructions";
import CheckinCheckoutAdjustments from "./pages/CheckinCheckoutAdjustments";
import DataCollection from "./pages/dataCollection";

/** Check if user is on a mobile device */
function isMobile() {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
}

const App = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  let tokenParams = searchParams.get("token");
  let token = null;
  let listingId = null;

  if (tokenParams) {
    tokenParams = tokenParams.split(",");
    token = tokenParams[0];
    listingId = tokenParams[1];
  }

  const [effectDone, setEffectDone] = useState(false);
  const location = useLocation();

  useWebSocket(WEBSOCKET_URL, {
    onOpen: () => {
      console.log("WebSocket connection established.");
    },
    share: true,
    filter: () => false,
    retryOnError: true,
    shouldReconnect: () => true,
  });

  function launchIntercom() {
    (function () {
      var w = window;
      var ic = w.Intercom;
      if (typeof ic === "function") {
        ic("reattach_activator");
        ic("update", w.intercomSettings);
      } else {
        var d = document;
        var i = function () {
          i.c(arguments);
        };
        i.q = [];
        i.c = function (args) {
          i.q.push(args);
        };
        w.Intercom = i;
        var l = function () {
          var s = d.createElement("script");
          s.type = "text/javascript";
          s.async = true;
          s.src = "https://widget.intercom.io/widget/ipchktdn";
          var x = d.getElementsByTagName("script")[0];
          x.parentNode.insertBefore(s, x);
        };
        if (document.readyState === "complete") {
          l();
        } else if (w.attachEvent) {
          w.attachEvent("onload", l);
        } else {
          w.addEventListener("load", l, false);
        }
      }
    })();
  }

  useEffect(() => {
    const setupUser = async () => {
      console.log("PATH: ", location.pathname);

      // Set Intercom settings
      window.intercomSettings = {
        api_base: "https://api-iam.intercom.io",
        app_id: "ipchktdn",
      };

      // Logged-in statuses (from the back end):
      // 1 -> valid user, can login
      // 2 -> valid user, but can't login (ready_to_login=false)
      // 3 -> user not logged in
      // 4 -> valid user, but no PMS info
      // 5 -> (escapia user who has clicked "i submitted a ticket" button)

      let loginStatus;
      let userId = null;
      const EXTERNAL_ROUTES = ["/login", "/signup", "/resetPassword", "/login/", "/signup/", "/resetPassword/"];

      // If user is on /logout, don't auto-navigate
      if (location.pathname === "/logout" || location.pathname === "/logout/") {
        setEffectDone(true);
        return;
      }

      // Attempt login via token in the URL
      if (token) {
        userId = await tokenToUserId(token);
        localStorage.setItem("besty_user_token", token);

        // If token is valid, loginStatus=1.
        // If the user is on reset password page, we treat them as not logged in (loginStatus=3).
        loginStatus =
          userId && !(location.pathname === "/resetPassword" || location.pathname === "/resetPassword/") ? 1 : 3;
      }

      // If no token param, try the local storage or normal login
      if (token === null) {
        const userResult = await getUser();
        loginStatus = userResult.loginStatus;
        token = userResult.userToken;
        userId = userResult.userId;
      }

      console.log(`Location: ${location.pathname}, loginStatus: ${loginStatus}`);

      // 3 -> user not logged in
      if (loginStatus === 3) {
        // if user tries external route while not logged in, let them proceed
        if (EXTERNAL_ROUTES.includes(location.pathname)) {
          setEffectDone(true);
          return;
        }
        // otherwise, go to login
        navigate("/login");
        setEffectDone(true);

        // 4 -> user is logged in but hasn't provided PMS credentials
      } else if (loginStatus == 4) {
        console.log(`loginStatus=${loginStatus}, setting to /apiCreds`);
        const queryParams = new URLSearchParams(location.search);
        navigate("/apiCreds", {
          state: {
            enterInfo: true,
            userId,
            userToken: token,
            pms: queryParams.get("pms"),
            apiError: queryParams.get("apiError") === "true",
          },
        });
        setEffectDone(true);

        // 5 -> escapia user who has clicked "i submitted ticket"
      } else if (loginStatus == 5) {
        console.log("loginStatus=5, setting to apiCreds w/ the 'waiting on Besty' message...");
        navigate("/apiCreds", {
          state: { enterInfo: false, userId, userToken: token, pms: "escapia" },
        });
        setEffectDone(true);
      } else {
        // Logged in
        console.log(`loginStatus= ${loginStatus} so doing default`);

        // Pull user info, including fields from BOTH branches
        const {
          name,
          avatar,
          username,
          createdAt,
          unansweredMessages,
          plan,
          period,
          trialEnd,
          trialHasPassed,
          pastDue,
          showMessagePopup,
          userToken,
          userInviteToken,
          isFirstTime,
          pms,
          isFirstTimeListingsReady,
          perUserPrompts,
          neverEscalateDraftMode,
          offerLanguage,
          summaryLanguage,
          autopilotSignature,
          autoEnableListings,
          extensionInstalledAt,
          webAppCredsIsExpired,
          templateFirstName,
          numListings,
          widgetEligible,
          widgetEnabled,
          churnkeyHash, // From churnkey branch
          stripeCustomerId, // From churnkey branch
          listingSetsEnabled, // From main
        } = await getUserInfo({ userToken: token });

        // Save to Redux
        dispatch(
          setUserInfo({
            ...{ avatar, username, name },
            createdAt,
            plan,
            period,
            userId,
            trialEnd,
            trialHasPassed,
            pastDue,
            showMessagePopup,
            userToken,
            userInviteToken,
            listingId, // for token-based login scenario
            isFirstTime,
            pms,
            isFirstTimeListingsReady,
            perUserPrompts,
            neverEscalateDraftMode,
            offerLanguage,
            summaryLanguage,
            autopilotSignature,
            autoEnableListings,
            extensionInstalledAt,
            webAppCredsIsExpired,
            templateFirstName,
            numListings,
            widgetEligible,
            widgetEnabled,
            churnkeyHash,
            stripeCustomerId,
            listingSetsEnabled,
          })
        );

        dispatch(setUnansweredMessages(unansweredMessages));

        // Update Intercom with user info
        window.intercomSettings = {
          ...window.intercomSettings,
          name,
          email: username,
          id: userId,
          plan,
          pms,
          numListings,
        };

        // Edge cases after user is logged in
        const ppms = (pms || "").toLowerCase();
        const pmsThatNeedsEmailPassword = ["streamline", "hostfully", "escapia"];

        // If plan is "off", direct user to marketing page
        if (plan === "off") {
          window.location.href = "https://getbesty.ai";
          return;
        }

        // If user tries an external route while already logged in, redirect them to "/"
        if (
          EXTERNAL_ROUTES.includes(location.pathname) ||
          location.pathname === "/apiCreds" ||
          location.pathname === "/apiCreds/"
        ) {
          window.location.href = "/";
          setEffectDone(true);
          return;
        }

        // if (plan === "paid" && pastDue) navigate("/paymentFailed");
        //   ^-- can re-enable when going live

        if (plan === "free") {
          // For free plan, push to trial page if they’re not there yet
          if (location.pathname !== "/trial") {
            console.log("Redirecting to /trial");
            navigate("/trial");
          }
        } else if (plan === "trial" && trialHasPassed) {
          // Trial ended, prompt upgrade
          navigate("/upgrade");
        } else if (pmsThatNeedsEmailPassword.includes(ppms)) {
          // If user hasn't set up web access tokens for certain PMS
          const haveWebTokensBeenInitialized = (await getWebAccessTokensInitialized(userId)).initialized;
          if (!haveWebTokensBeenInitialized) {
            navigate("/webAccessInstructions", {
              state: { userId: userId, pms: ppms, token: token },
            });
            setEffectDone(true);
            return;
          }
        } else if (!isMobile()) {
          // For desktop users, if it’s their first time, push them to listings or settings
          if (isFirstTimeListingsReady) {
            await setFirstTimeListingsReady({ userToken });
            navigate("/listings");
          } else if (isFirstTime) {
            navigate("/settings");
          }
        }
        setEffectDone(true);
      }
    };

    setupUser();
    launchIntercom();
  }, []);

  if (!effectDone) {
    return <Preloader />;
  }

  // If everything is loaded, show our routes
  return (
    <>
      <Suspense fallback={<Preloader />}>
        <Routes>
          <Route path="/webAccessInstructions" element={<WebAccessInstructions />} />
          <Route path="/apiCreds" element={<ApiCreds />} />
          <Route path="/resetPassword" element={<ResetPassword />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route
            path="/checkoutSuccess"
            element={
              <PrivateRoute>
                <CheckoutSuccess />
              </PrivateRoute>
            }
          />
          <Route
            path="/checkoutCancelled"
            element={
              <PrivateRoute>
                <CheckoutCancelled />
              </PrivateRoute>
            }
          />
          <Route
            path="/paymentFailed"
            element={
              <PrivateRoute>
                <PaymentFailed />
              </PrivateRoute>
            }
          />
          <Route
            path="/trial"
            element={
              <PrivateRoute>
                <FreeTrial />
              </PrivateRoute>
            }
          />
          <Route
            path="/upgrade"
            element={
              <PrivateRoute>
                <UpgradePlan />
              </PrivateRoute>
            }
          />
          <Route
            path="/unanswered"
            element={
              <PrivateRoute>
                <ContentPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/widgetMessages"
            element={
              <PrivateRoute>
                <WidgetMessages />
              </PrivateRoute>
            }
          />
          {/* Default to /listings if user is logged in */}
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Listings />
              </PrivateRoute>
            }
          />
          <Route
            path="/listings"
            element={
              <PrivateRoute>
                <Listings />
              </PrivateRoute>
            }
          />
          <Route
            path="/reviews"
            element={
              <PrivateRoute>
                <Reviews />
              </PrivateRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <PrivateRoute>
                <Settings />
              </PrivateRoute>
            }
          />
          <Route
            path="/broadcast"
            element={
              <PrivateRoute>
                <Broadcast />
              </PrivateRoute>
            }
          />
          <Route
            path="/refer"
            element={
              <PrivateRoute>
                <Refer />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/copilot"
            element={
              <PrivateRoute>
                <Copilot />
              </PrivateRoute>
            }
          />
          <Route
            path="/review-tasks"
            element={
              <PrivateRoute>
                <ReviewTasksPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/tasks"
            element={
              <PrivateRoute>
                <TasksPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/checkinCheckoutAdjustments"
            element={
              <PrivateRoute>
                <CheckinCheckoutAdjustments />
              </PrivateRoute>
            }
          />
          <Route
            path="/automated-messages"
            element={
              <PrivateRoute>
                <AutomatedMessages />
              </PrivateRoute>
            }
          />
          {/*<Route*/}
          {/*  path="/dataCollection"*/}
          {/*  element={*/}
          {/*    <PrivateRoute>*/}
          {/*      <DataCollection />*/}
          {/*    </PrivateRoute>*/}
          {/*  }*/}
          {/*/>*/}
        </Routes>
      </Suspense>
    </>
  );
};

const Root = () => {
  return (
    <Router>
      <App />
    </Router>
  );
};

export default Root;
