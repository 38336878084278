// TypeScript object for 'automated_message' table
import { RootState } from "../store";
import { createSlice } from "@reduxjs/toolkit";
import { MessageJourney } from "../../api";

const initialState: { journeys: MessageJourney[]; selectedJourneyIndex: number } = {
  journeys: [],
  selectedJourneyIndex: 0,
};

const JourneySlice = createSlice({
  name: "journeys",
  initialState,
  reducers: {
    setSelectedJourneyIndex: (state, action) => {
      state.selectedJourneyIndex = action.payload;
    },
    updateAutomatedMessages: (state, action) => {
      state.journeys[state.selectedJourneyIndex].automated_messages = action.payload;
    },
    saveJourney: (state, action) => {
      state.journeys[state.selectedJourneyIndex] = action.payload;
    },
    addNewJourney: (state, action) => {
      state.selectedJourneyIndex = state.journeys.length;
      state.journeys = [...state.journeys, action.payload];
    },
    setAllJourneys: (state, action) => {
      state.journeys = action.payload;
      state.selectedJourneyIndex = 0;
    },
  },
});

export const { setSelectedJourneyIndex, updateAutomatedMessages, saveJourney, addNewJourney, setAllJourneys } =
  JourneySlice.actions;

export default JourneySlice.reducer;

export const GetAllJourneys = (state: RootState) => state.journeys.journeys || [];

export const GetJourneyIndex = (state: RootState) => state.journeys.selectedJourneyIndex;
