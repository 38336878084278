import { createSlice } from "@reduxjs/toolkit";
import { TimeUpdatesType } from "src/api";

export interface TimeUpdatesState {
  data: TimeUpdatesType[];
}

const initialState: TimeUpdatesState = {
  data: [],
};

const timeUpdatesSlice = createSlice({
  name: "timeUpdates",
  initialState,
  reducers: {},
});

export default timeUpdatesSlice.reducer;
