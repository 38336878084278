import { useAppDispatch, useAppSelector } from "../redux/hooks";
import React, { Suspense, useCallback, useEffect } from "react";
import Layout from "../layouts";
import Preloader from "../components/preloader";
import TimeAdjustments from "../containers/arrivalDepartureAdjusments/main";
import { getThread, getTimeUpdates } from "../api";
import { getUserInfo } from "../redux/slices/global";
import { useQuery } from "react-query";
import { Spinner } from "@doar/components";
import { getSelectedThreadId, setSelectedThreadId, setThreads } from "../redux/slices/messages";

const editablePmses = ["hostaway", "guesty"];

const CheckinCheckoutAdjustments = () => {
  const dispatch = useAppDispatch();
  const { userToken, pms } = useAppSelector(getUserInfo);
  const threadId = useAppSelector(getSelectedThreadId);
  console.log("Thread ID:", threadId);

  const [timeUpdateData, setTimeUpdateData] = React.useState([]);

  const { data, isLoading } = useQuery(["timeAdjusts", userToken], () => getTimeUpdates({ userToken }), {
    enabled: !!userToken,
    staleTime: 60 * 1000, // ONe Minute,
    onSuccess: () => {
      console.log("Successfully fetched time adjustments");
    },
    onError: (error) => {
      console.log(error);
    },
  });

  // Process time updates on load
  useEffect(() => {
    const timeUpdates = data?.TimeUpdates ?? [];
    const filteredTimeUpdates = timeUpdates.filter((item) => {
      return !item.hidden_since_last_change;
    });
    setTimeUpdateData(filteredTimeUpdates);
  }, [data?.TimeUpdates]);

  useEffect(() => {
    const handleThreadChange = async () => {
      // TODO: Cache Responses
      const response = await getThread({ userToken, hostaway_conversation_id: threadId });
      const thread = response.thread;

      // Update the state
      dispatch(setThreads([thread]));
    };

    if (threadId) {
      handleThreadChange();
    }
  }, [dispatch, threadId, userToken]);

  const setConvoId = useCallback(
    (convoId) => {
      console.log("Setting Convo ID to:", convoId);
      dispatch(setSelectedThreadId(convoId));
    },
    [dispatch]
  );

  useEffect(() => {
    const convoId = timeUpdateData[0]?.hostaway_conversation_id ?? null;
    setConvoId(convoId);
  }, [setConvoId, timeUpdateData]);

  return (
    <Layout hideFooter={true} hasSidebar={false}>
      <div style={{ paddingTop: "10px" }}>
        <Suspense fallback={<Preloader />}>
          {isLoading ? (
            <Spinner />
          ) : timeUpdateData.length === 0 ? (
            <div>No time adjustments found</div>
          ) : (
            <TimeAdjustments
              timeUpdates={timeUpdateData}
              convoId={threadId}
              setConvoId={setConvoId}
              setTimeUpdateData={setTimeUpdateData}
              isEditablePms={editablePmses.includes(pms)}
            />
          )}
        </Suspense>
      </div>
    </Layout>
  );
};

export default CheckinCheckoutAdjustments;
