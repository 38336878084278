import styled, { device, css } from "@doar/shared/styled";
import { Media, MediaBody, Heading } from "@doar/components";

export const StyledMedia = styled(Media)`
    border: none;
    width: 100%;
`;

export const MediaContainer = styled(({ isAutopilot, ...rest }) => <div {...rest} />)`
    border-radius: 0.25rem;
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
    border: none;
    color: black;
    margin-top: 10px;

    background-color: rgba(101,172,247,0.35);

  ${({ isAutopilot }) =>
      !!isAutopilot &&
      css`
          background-color: rgb(207, 241, 222);
      `}

    ${device.nonMobile} {
        margin-left: 30%;
        padding: 15px;
    }
    ${device.mobile} {
        margin-left: 10%;
        padding: 10px;
    }
`;

export const AvatarDiv = styled.div`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    position: relative;
`;

export const StyledHeading = styled(({ ...rest }) => (
    <Heading {...{ mb: "3px", ...rest }} />
))`
    font-weight: 600;
    font-size: 14px;
    line-height: 1.5;
`;
