import { CenteredModalHeader, StyledButton, CenteredFooter } from "./style";
import { Modal, ModalBody, ModalTitle, ModalFooter } from "@doar/components";
import { getSelectedThreadId, removeThread } from "src/redux/slices/messages";
import { useAppSelector, useAppDispatch } from "src/redux/hooks";
import { useMutation } from "react-query";
import { archiveThread } from "src/api";
import { getUserInfo } from "src/redux/slices/global";

const ArchiveChatModal = ({ showModal, setShowModal }) => {
    const { userId, userToken } = useAppSelector(getUserInfo);
    const dispatch = useAppDispatch();
    const selectedThreadId = useAppSelector(getSelectedThreadId);

    const { mutate } = useMutation(archiveThread);
    const handleArchive = () => {
        mutate({ threadId: selectedThreadId, userToken });
        dispatch(removeThread(selectedThreadId));
        setShowModal(false);
    };
    return (
        <Modal
            show={showModal}
            onClose={() => {
                setShowModal(false);
            }}
        >
            <CenteredModalHeader>
                <ModalTitle style={{fontSize: "16px"}} >
                    Archive this chat?
                </ModalTitle>
            </CenteredModalHeader>
            <ModalBody style={{ display: "flex", justifyContent: "center", padding: "5px 0px 8px 0px" }}>
                <StyledButton
                    style={{backgroundColor: "rgba(101,172,247,0.35)", color: "black", marginRight: "5px"}}
                    onClick={handleArchive}
                >
                    Yes
                </StyledButton>
                <StyledButton
                    style={{backgroundColor: "rgba(217,217,217,0.38)", color: "black"}}
                    onClick={() => setShowModal(false)}
                >
                    No
                </StyledButton>
            </ModalBody>
            <CenteredFooter>
                Archive a chat to remove it from this page without sending a message.
            </CenteredFooter>
        </Modal>
    );
};

export default ArchiveChatModal;
