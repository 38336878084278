import { Home, Mail, MessageSquare, PieChart, Settings, Terminal } from "react-feather";
import { IMenu } from "types";

const menus: IMenu[] = [
  {
    id: 1,
    label: "Analytics",
    url: "/dashboard",
    Icon: PieChart,
    lite: true,
  },
  {
    id: 2,
    label: "Unanswered Messages",
    url: "/unanswered",
    Icon: Mail,
    lite: false,
  },
  {
    id: 3,
    label: "Listings",
    url: "/listings",
    Icon: Home,
    lite: false,
  },
  {
    id: 7,
    label: "Review Responder",
    url: "/reviews",
    Icon: MessageSquare,
    lite: false,
  },
  {
    id: 8,
    label: "Tasks",
    url: "/tasks",
    Icon: MessageSquare,
    lite: false,
  },
  {
    id: 4,
    label: "Checkin/Checkout Adjustments",
    url: "/checkinCheckoutAdjustments",
    Icon: Settings,
    lite: true,
  },
  // {
  //   id: 11,
  //   label: "Automated Messages",
  //   url: "/automated-messages",
  //   Icon: Terminal,
  //   lite: false,
  // },
  {
    id: 10,
    label: "Broadcast",
    url: "/broadcast",
    Icon: Mail,
    lite: false,
  },
  // {
  //     id: 4,
  //     label: "Copilot",
  //     url: "/copilot",
  //     Icon: Compass,
  // },
  {
    id: 5,
    label: "Settings",
    url: "/settings",
    Icon: Settings,
    lite: true,
  },

  /*
  {
    id: 6,
    label: "Refer & Earn $100",
    url: "/refer",
    Icon: DollarSign,
    lite: false
  },
  */
  {
    id: 9,
    label: "Widget Messages",
    url: "/widgetMessages",
    Icon: Mail,
    lite: false,
  },
];

export default menus;
