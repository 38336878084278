import styled, { device } from "@doar/shared/styled";
import { Button } from "@doar/components";

export const StyledArea = styled.div`
    border-bottom: 1px solid rgba(72, 94, 144, 0.16);
    margin: 0px;
    padding: 15px;
    ${device.mobile} {
      padding: 8px 28px 8px 8px;
    }
    font-weight: 400;
    font-size: 14px;
    position: relative;
`;

export const DateArea = styled.div`
    font-size: 12px;
    font-style: italic;
    color: #aaaaaa;
    line-height: 1.5;
`;

export const StatusArea = styled.div`
    font-size: 12px;
    font-style: normal;
    color: #aaaaaa;
    line-height: 1.5;
`;

// background-color: rgba(101,172,247,0.35);
export const DeleteButton = styled(({ isHovered, ...rest }) => (
    <Button {...rest} />
))`
    position: absolute;
    top: 2px;
    right: -2px;
    width: 22px;
    height: 22px;
    color: black;
    margin: 5px;
    background-color: rgba(72, 94, 144, 0.16);

    ${device.nonMobile} {
        display: none;
    }
`;

