import { MediaBody } from "@doar/components";
import {
    StyledMedia,
    StyledHeading,
    MediaContainer,
    AvatarDiv,
    MarginDiv,
} from "./style";
import Metadata from "../metadata";
import MessageText from "../message-text";

const MessageCardRTL = ({ text, sender, timestamp, isAutopilot, languages, selectedLanguage, setSelectedLanguage }) => {
    return (
        <StyledMedia>
            <MediaContainer isAutopilot={isAutopilot} >
                <MediaBody >
                    <MessageText {...{ text }} />
                    <Metadata {...{ timestamp, prefix: sender, languages, selectedLanguage, setSelectedLanguage }} />
                </MediaBody>
            </MediaContainer>
        </StyledMedia>
    );


};

export default MessageCardRTL;
