import styled, { themeGet, device } from "@doar/shared/styled";

/* background-color: rgb(248, 249, 252); */
//background-color: rgb(229,233,242);
export const StyledArea = styled.div`
  ${device.nonMobile} {
    height: ${(props) => (props.fullHeight ? "100%" : "80%")};
    max-height: ${(props) => (props.fullHeight ? "100%" : "80%")};
  }
  overflow-y: scroll;
  padding: 20px;
  ${device.mobile} {
    padding: 12px;
    flex-grow: 1;
  }
  display: flex;
  flex-direction: column;
  background-color: white;
  //background-color: #333333;
`;

export const LeftDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const RightDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
